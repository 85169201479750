<template>
  <div>
    <div>
      <b-carousel
        id="carousel-1"
        v-model="slide"
        :interval="4000"
        controls
        indicators
        background="#ababab"
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >
        <!-- Text slides with image -->
        <b-carousel-slide img-src="../../public/art-2-1.gif"></b-carousel-slide>

        <!-- Slides with custom text -->
        <b-carousel-slide img-src="../../public/art-2-2.gif">
        </b-carousel-slide>

        <!-- Slides with image only -->
        <b-carousel-slide img-src="../../public/art-2-3.gif"></b-carousel-slide>
      </b-carousel>
    </div>
    <div style="padding: 1em">
      <h3>About the work</h3>
      <div style="display: flex; justify-content: center">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/kZVug2eVyx0"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <div style="padding: 1em" class="art-description">
      <p>
        We imagine the future of explainable AI, a movement that seeks to
        explain the decisions made by an AI system. We seek to create pathways
        for explanations that are truly human centered and empathetic. We seek
        to develop ‘activists’ out of users who can question and seek to find
        answers to their questions pertaining to digital interactions which
        puzzle them. Hence moving beyond the seduction of User Interface design.
        We seek to create explanations - which look at users as learners and
        hence align with various pedagogical frameworks. We imagine a future
        which is just, accountable and equitable.
      </p>
    </div>
    <div style="padding: 1em"></div>

    <div style="padding: 1em">
      <h3>About the Artists</h3>
    </div>
    <div style="padding: 1em" class="artist-name">
      <ul>
        <li>
          <a
            target="_blank"
            href="https://www.linkedin.com/in/daksha-dixit-31161a166"
            >Daksha Dixit</a
          >
        </li>
      </ul>
      <ul>
        <li>
          <a
            target="_blank"
            href="https://www.linkedin.com/in/simran-singh-7bba87137/"
            >Simran Singh
          </a>
        </li>
      </ul>
      <ul>
        <li>
          <a
            target="_blank"
            href="https://www.linkedin.com/in/kartik-handa-36983472"
            >Kartik Handa</a
          >
        </li>
      </ul>
      <p>From Srishti Institute of Art, Design and Technology</p>
    </div>
    
  </div>
</template>

<script>
export default {
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
};
</script>